import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import axiosConfig from './lib/axiosConfig';
import VueClipboard from 'vue-clipboard3';
import VueYandexMetrika from 'vue3-yandex-metrika' 
import router from './router';

const app = createApp(App);
app.use(store);

const options = {
  // You can set your default options here
};
app.use(Toast, options);
app.use(VueClipboard);
app.use(router);

app.use(VueYandexMetrika, {
    id: 95655921,
    router: router,
    env: process.env.NODE_ENV
    // other options
})

//const axiosInstance = createAxiosInstance(store);
//app.config.globalProperties.$axios = axiosInstance;
app.config.globalProperties.$axios = axiosConfig;

app.config.globalProperties.$encodeFilterParameter = function(filters) {
  const filterKeys = Object.keys(filters);
  const encodedParams = filterKeys.map(key => `filters[${encodeURIComponent(key)}]=${encodeURIComponent(filters[key])}`);
  return encodedParams.join('&');
};



app.mount('#app');


