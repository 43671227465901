<template>
  <div class="container mt-4">
    <div v-if="collection">
      <div class="row" v-if="collection.chatbot_name !== 'zvonalitik'">

        <div class="col-lg-4 col-md-6 col-sm-12 mb-3 p-0 p-md-2 ps-md-0" >
          <img :src="collection.thumbs.big" class="mb-3 cover" v-if="collection.thumbs" />
          <div class="text-center bg-success text-white rounded p-2" v-if="collection && collection.materials && collection.materials.avg_score_test && collection.materials.avg_score_test !== -1"><b>{{collection.materials.avg_score_test_caption}}</b><br>Ваш средний балл<br>
            <b>{{collection.materials.avg_score_test}} из 100</b>
          </div>
        </div>


        <div class="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center p-0 p-md-2" >
          <div>
            
            <div class="text-muted small" v-if="!chatbotName && 0">Автор: <a :href="'https://t.me/' + collection.username">{{collection.username}}</a> <i class="bi bi-calendar ms-2"></i> Обновлено: {{collection.updated_at}}</div>

            <h1>{{ collection.caption }}</h1>
            <div class="" v-html="collection.description"></div>
            <a class="btn btn-primary" v-if="collection.tg_join_link && !chatbotName" :href="collection.tg_join_link" target="_blank">Открыть курс в Telegram</a>
          </div>
        </div>

      </div>

<!--       <div class="row mb-2" v-if="collection.chatbot_name == 'zvonalitik'">
         <h1>{{ collection.caption }}</h1>
          <div class="text-muted small">Загрузил: <a :href="'https://t.me/' + collection.username">{{collection.username}}</a> <i class="bi bi-calendar ms-2"></i> Обновлено: {{collection.updated_at}}</div>         
      </div>
 -->      
<!--     <div class="cover-bg" :style="{'background-image': `url(${collection.thumbs.big})`}">
        <h1 class="mb-4">{{ collection.caption }}</h1>
    </div>
 -->
    <div class="row">
      <h3 class="card-title mb-2 mt-4">{{collection.materials_list_caption}}:</h3>
      
      <!-- <div class="alert alert-warning" v-if="collection && collection.materials && !collection.material.materials.length">
        <h6>Материалы не добавлены</h6>

      </div> -->

      <ul class="list-group p-0" v-if="collection && collection.materials && collection.materials.materials.length">
        <li v-for="material in collection.materials.materials" :key="material.id" class="list-group-item">
          <div class="row">
            <div class="col-lg-2 col-sm-12 col-md-2" v-if="material.smallpicture">
              <img
                v-if="material.smallpicture"
                :src="material.smallpicture"
                alt=""
                class="img-thumbnail"
              />

            </div>
            <div class="col-lg-10 col-md-10 col-sm-12">
              <router-link :to="{ name: 'Article', params: { id: material.hash_inplaylist } }" v-if="!chatbotName">{{ material.caption }}</router-link>
              <h6 v-if="chatbotName">{{ material.caption }}</h6>
              <div class="text-muted small" v-if="material.shorttext_in_list">{{material.shorttext_in_list}}...</div>
              <div v-if="material.test_results" class="text text-info">Результаты теста: {{material.test_results.score}} из 100 баллов</div>
              <div v-if="collection.show_material_open_status_info">
                <div v-if="material.open_status === 0" class="mt-1">
                  <div class="">
                    <span class="badge bg-danger">🔒 Закрыто</span>&nbsp;
                    <span v-if="material.closed_reason" class="text-muted small">{{material.closed_reason}}</span>
                  </div>
                  <div v-if="material.description" class="text-muted text-sm mt-2" v-html="material.description">

                  </div>
<!--                   <span v-if="material.open_by_date" class="text-muted small">Доступ будет открыт {{ material.open_by_date }}</span>
                  <span v-if="material.open_by_test_score" class="text-muted small">Нужна оценка за предыдущий тест: {{ material.open_by_test_score }} баллов</span>
                  <span v-if="material.open_by_payment" class="text-muted small">Для доступа нужна оплата {{ material.open_by_payment }} ₽</span>
 -->

                </div>
                
                <div v-else-if="material.open_status && !chatbotName" class="mt-2">
                  <span class="badge bg-success">Открыто</span>&nbsp;
                  <span v-if="material.opened_reason" class="text-muted small">{{material.opened_reason}}</span>
                </div>

<!--                 <div v-else-if="material.open_status === 1 && !chatbotName" class="mt-2">
                  <span class="badge bg-success">Открыто</span>&nbsp;
                </div>
                <div v-else-if="material.open_status === 2 && !chatbotName" class="mt-2">
                  <span class="badge bg-success">Открыто</span>&nbsp;
                  <span v-if="material.open_by_test_score" class="text-muted small">Оценка за предыдущий тест: {{ material.open_status_test_score }}</span>
                </div>
 -->
              </div>
              <button class="btn btn-primary btn-sm w-100 w-sm-auto mt-2" v-if="chatbotName && material.open_status !== 0" @click="selectMaterialForBot(material.hash_inplaylist)">Открыть</button>
            </div>

            <div class="col" v-if="collection.chatbot_name == 'zvonalitik'">
              <div :class="material.score_report.color_class"><strong>{{material.score_report.report_score.good_percent}}%</strong></div>

            </div>
          </div>
        </li>
      </ul>
    </div>

      <div class="mt-4 card" v-if="!isAuthenticated  && !configData.disable_telegram_auth">
        <a name="auth"></a>
        <h4 class="card-header"><i class="bi bi-person-fill"></i> Войдите в личный кабинет</h4>
        <div class="card-body">
          <!-- <button type="button" class="btn btn-primary" @click="goToTGBot(collection)" :disabled="true">Записаться на курс</button> -->

          <div class="alert alert-warning">
            <p>Чтобы пройти курс, войдите через Telegram.</p>
            <telegram-auth></telegram-auth>
          </div>

        </div>
      </div>

      <div class="mt-4" v-if="isAuthenticated && 0">
        <div class="alert alert-info" v-if="!collection.user_status">
          <!-- <button type="button" class="btn btn-primary" @click="applyNewUser">Записаться на курс</button> -->
          <button type="button" class="btn btn-primary" @click="goToTGBot(collection)">Записаться на курс</button>
        </div>

        <div class="alert alert-info" v-if="collection.user_status && collection.user_status.id && collection.user_status.status == 'applied'">
          <div class="">{{collection.user_status.status_caption}} {{collection.user_status.status_date}}</div>
          <button type="button" class="btn btn-danger" @click="cancelApplyNewUser">Отменить</button>
        </div>

        <div class="alert alert-info" v-if="collection.user_status && collection.user_status.id && collection.user_status.status == 'cancelled'">
          <div class="">{{collection.user_status.status_caption}} {{collection.user_status.status_date}}</div>
          <button type="button" class="btn btn-primary" @click="applyNewUser">Отправить заявку повторно</button>
        </div>

        <div class="alert alert-info" v-if="collection.user_status && collection.user_status.id && collection.user_status.status == 'joined'">
          <div class="">{{collection.user_status.status_caption}} {{collection.user_status.status_date}}</div>
          <button type="button" class="btn btn-warning" @click="detachNewUser">Покинуть курс</button>
        </div>

        <div class="alert alert-info" v-if="collection.user_status && collection.user_status.id && collection.user_status.status == 'detached'">
          <div class="">{{collection.user_status.status_caption}} {{collection.user_status.status_date}}</div>
          <button type="button" class="btn btn-primary" @click="applyNewUser">Отправить заявку повторно</button>

        </div>

        <div class="alert alert-info" v-if="collection.user_status && collection.user_status.id && collection.user_status.status == 'declined'">
          <div class="">{{collection.user_status.status_caption}} {{collection.user_status.status_date}}</div>
        </div>

      </div> 

    </div>
    <div v-else>
      <div class="text-center">
        <spinner-component :visible="true" class="mt-4"></spinner-component>
      </div>

      
    </div>
  </div>
</template>


<script>

import { mapActions, mapGetters, useStore} from 'vuex';
import { ref, onMounted, computed, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import TelegramAuth from './TelegramAuth.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

export default {
  components: {
    TelegramAuth,
    SpinnerComponent
  }, 
  computed: {

    ...mapGetters('app', ['isMobile', 'configData', 'isPreview', 'chatbotName']) 
  },  
  methods:{
    ...mapActions("collection", ["applyUser", "cancelApplyUser", "detachUser"]), 

    goToTGBot(){


    },
    applyNewUser() {
      //console.log(this.user);
      this.applyUser({ 
        collectionId: this.collectionId, 
        user: this.user
      })
      .then(response => {
        this.collection.user_status = response.user_status
        console.log(response);  
      });

      
      //this.collection.user_status =
    },

    selectMaterialForBot(hash_inplaylist) {
      this.$store.dispatch('tgconnector/sendCommandToBot', {
        params: { material_hash: hash_inplaylist, command: 'show_course_material'}
      });
    },

    detachNewUser() {
      
      this.detachUser({ 
        collectionId: this.collectionId, 
        user: this.user
      })
      .then(response => {
        this.collection.user_status = response.user_status
        //console.log(response);  
      });

      
      //this.collection.user_status =
    },

    cancelApplyNewUser() {
      console.log(this.user);
      this.cancelApplyUser({ 
        collectionId: this.collectionId, 
        user: this.user
      })
      .then(response => {
        this.collection.user_status = response.user_status
        console.log(response);  
      });
    }

  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const collectionId = ref(null);
    const collection = ref(null);

    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    const user = computed(() => store.getters['user/getUser']);

    // Получение параметра "id" из URL

    watchEffect(() => {
      console.log(isAuthenticated.value);
      if (isAuthenticated.value) {
        store.dispatch('collection/fetchCollection', collectionId.value)
        .then((data) => {
          collection.value = data.collection;         
        })
        .catch((error) => {
          console.log(error);
        });
        
      }
    });

    onMounted(() => {
      collectionId.value = route.params.id;
      console.log(collectionId.value);
      
      store.dispatch('collection/fetchCollection', collectionId.value)
        .then((data) => {
          console.log(data);
          // Здесь вы можете обновить реактивное свойство "collection"
          // с данными о полученной подборке
          collection.value = data.collection;

          document.title = data.collection.caption;

          // store.dispatch('channel/fetchChannel', {collectionId: collection.value.channel_id})
          // .then(() => {

          // })
          // .catch((error) => {
          //   console.log(error);
          //   // Обработка ошибок при загрузке информации о подборке
          // });
         

        })
        .catch((error) => {
          console.log(error);
          // Обработка ошибок при загрузке информации о подборке
        });
         
      // Загрузка информации о подборке по ее ID
      // Вы можете здесь использовать свой метод или экшен из хранилища, чтобы загрузить данные о подборке.
      // Например, store.dispatch('collection/fetchCollection', collectionId.value)
      // Используйте computed свойство вместо прямой передачи данных в шаблон
      // для более оптимального обновления и реактивности.
    });

    return {
      collectionId,
      collection,
      isAuthenticated,
      user
    };
  },
};
</script>

<style>
/* Дополнительные стили Bootstrap можно добавить здесь */
.cover{
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;

}

.cover-bg{
  display: flex;
  color:#FFF;
  justify-content: space-around;
  align-items: center;
  height: 250px;  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
</style>
