<template>
  <div :class="displayFormat + ' ' +  (collection.channel_id == 59)?'without-thumbs':''" class="other-articles">
    <div class="card border-0" v-if="displayFormat === 'vertical'">
      <div class="">
        <div v-if="displayFormat === 'vertical'">
          <div v-if="collection.materials_list_in_collection_caption">
            <h4 class="card-title">{{collection.materials_list_in_collection_caption}}</h4>
            <h5 class="mb-1"><a :href="collection.link">{{collection.caption}}</a></h5>
            <div class="small text-muted mb-4"> <span class="icon me-2"><i class="bi bi-calendar"></i></span> {{collection.updated_at}}</div>
          </div>

          <div class="mb-1" v-if="collectionFilters">
            <select class="form-select mb-1" v-model="selectedCollectionFilter[filter_alias]" :key="filter_alias" @change="fetchCollectionMaterials(false)" v-for="filters_list, filter_alias  in collectionFilters">
              <option :value="'any'" >- {{filters_list.filter_caption}} -</option>
              <option v-for="filter in filters_list.list" :key="filter.alias" :value="filter.alias">{{ filter.caption }}</option>
            </select>
          </div>

         <div class="summary-card" v-if="collectionSummaryReport">
          <div class="">
            <span class="icon me-1"><i class="bi bi-star"></i></span> Ср. оценка по звонкам
          </div>
          <div class="summary">
            <div class="score d-flex align-items-center">
              <div class="icon me-1"><i :class="collectionSummaryReport.icon + ' ' + collectionSummaryReport.color_class"></i></div>
              <div class="val"><strong>{{ collectionSummaryReport.good_percent }}%</strong></div>
            </div>
          </div>
        </div>

        <!-- Плашка с общей продолжительностью разговора -->
        <div class="summary-card" v-if="collectionSummaryReport">
          <div class="">
            <span class="icon me-1"><i class="bi bi-clock"></i></span> Всего в разговоре
          </div>
          <div class="summary">
            <div class="val"><strong>{{ collectionSummaryReport.total_duration_minutes }} мин</strong></div>
          </div>
        </div>

        <!-- Плашка с средней продолжительностью звонка -->
        <div class="summary-card" v-if="collectionSummaryReport">
          <div class="">
            <span class="icon me-1"><i class="bi bi-telephone"></i></span> Ср. продолжительность звонка
          </div>
          <div class="summary">
            <div class="val"><strong>{{ collectionSummaryReport.avg_duration_minutes }} мин</strong></div>
          </div>
        </div>


          <div class="summary-card" v-if="collectionSummaryReport">
            <div class="">
               <span class="icon me-1"><i class="bi bi-credit-card"></i></span> Сумма за обработку звонков
            </div>
            <div class="summary">
              <div class="val"><strong>{{ collectionSummaryReport.total_sum_final }} ₽</strong></div>
            </div>
          </div>
          <!-- Плашка с тегами -->
          <div class="summary-card tags" v-if="collectionSummaryReport">
            <div class="tags mb-3">
              <span v-for="tag in tags" :key="tag.id" class="badge me-1" :style="{ backgroundColor: tag.color_hex }" 
                    @click="toggleTag(tag.id)" :class="{ 'selected': selectedTags.includes(tag.id) }">
                {{ tag.tag }} – {{tag.count}}
              </span>
            </div>
          </div>



          <div class="materials-list-scroll">
            <ul class="list-group"  v-if="collectionArticles.length">
              <li v-for="item in collectionArticles" :key="item.id" class="list-group-item" :class="(item.id == articleItem.id)?'hl':''">
                <div class="align-items-center">
                  <div>
                    <a :href="item.link" class="text-decoration-none">
                      <img :src="item.smallpicture" v-if="item.smallpicture" class="mb-2">
                    </a>
                  </div>
                  <div class="d-flex justify-content-between align-items-start">
                    <a :href="item.link" class="text-decoration-none">{{ item.caption }}</a>
                    <span class="badge d-flex align-items-center" :class="'bg-' + item.score_report.report_score.color_class" v-if="item.score_report">
                      {{item.score_report.report_score.good_percent}}%
                    </span>
                  </div>
                  <div v-if="item.open_status === 0 " class="alert alert-danger mb-0">
                      <span class="badge bg-danger">🔒 Закрыто</span> {{item.closed_reason}}

                  </div>

                  <div class="tags mb-2" v-if="item.tags && item.tags.length">
                    <span v-for="tag in item.tags" :key="tag.id" class="badge me-1" :style="{ backgroundColor: tag.color_hex }">{{ tag.tag }}</span>
                  </div>                

                  <div class="d-flex time-price mb-1 small" v-if="item.duration_minutes || (item.charges)">
                    <span v-if="item.duration_minutes" class="text text-muted me-2"><i class="bi bi-clock"></i> {{item.duration_minutes}}</span>
                    <span v-if="item.charges && item.charges.total_sum_final" class="text text-muted me-4"><i class="bi bi-credit-card"></i> {{item.charges.total_sum_final}} ₽</span>
                  </div>                
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from '@/config.js';
import { mapGetters } from 'vuex';

export default {
  props: {
    displayFormat: {
      type: String,
      default: 'vertical', // Default display format is vertical
    },
  },
  data() {
    return {
      collectionArticles: [], // Переименовали otherArticles в collectionArticles
      collection: {},
      collectionFilters: [],
      collectionSummaryReport: {},
      selectedCollectionFilter: {},
      selectedTags: [],
      tags: [],
    };
  },
  computed: {
    ...mapGetters('article', ['getArticleItem']),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
  },
  methods: {
    processFiltersParameters() {
      const queryParams = new URLSearchParams(this.$route.fullPath.split('?')[1]); // Получаем параметры из URL
      //const filters = {};

      for (const [key, value] of queryParams.entries()) {
        if (key.startsWith('filters[') && key.endsWith(']')) {
          const paramName = key.slice(8, -1); // Убираем скобки в имени параметра
          //filters[paramName] = value;
          this.selectedCollectionFilter[paramName] = value;
        }
      }
      //console.log(this.selectedCollectionFilter);

    },

toggleTag(tagId) {
  // Проверяем, был ли тег уже выбран
  const index = this.selectedTags.indexOf(tagId);
  if (index !== -1) {
    // Если тег уже выбран, удаляем его из массива выбранных тегов
    this.selectedTags.splice(index, 1);
  } else {
    // Если тег не был выбран, добавляем его в массив выбранных тегов
    this.selectedTags.push(tagId);
  }

  // Обновляем selectedCollectionFilter.tag_ids в соответствии с новыми выбранными тегами
  this.selectedCollectionFilter.tag_ids = this.selectedTags.join(',');

  // Повторно загружаем данные с учетом выбранных тегов
  this.fetchCollectionMaterials(false);
},


    async fetchCollectionMaterials(initFilter) { // 

      //console.log(initFilter);
      try {
        var params = {
            action: 'get_collection_articles', 
            selectedCollectionFilter: this.selectedCollectionFilter
            //groupAlias: this.articleItem.group_alias,
          }

        // Добавляем выбранные теги к фильтру
        if (this.selectedTags.length > 0) {
          params.selectedCollectionFilter.tag_ids = this.selectedTags.join(',');
        }

        //if (this.selectedCollectionFilter) params['collectionFilter'] = this.selectedCollectionFilter;

        const response = await this.$axios.get(API_URL, {
          params: params,
        });
        this.collectionArticles = response.data.collection_articles; 

        this.collection = response.data.collection; 
        this.collectionFilters  = response.data.collection_filters;
        this.collectionSummaryReport  = response.data.summary_report;

        if (!this.selectedTags.length) this.tags = response.data.tags_report;

        if (typeof initFilter !== 'undefined' && initFilter == true) {
          this.initSelectedCollectionFilter();
        }

        this.collectionArticles.forEach((article) => {
          article.link += '?' + this.$encodeFilterParameter(this.selectedCollectionFilter);
        });

      } catch (error) {
        console.error('Error fetching collection articles:', error);
      }
    },

    initSelectedCollectionFilter(){
      console.log(this.selectedCollectionFilter);
      for(var filter_alias in this.collectionFilters ){
        if (typeof this.selectedCollectionFilter[filter_alias] == 'undefined'){
          this.selectedCollectionFilter[filter_alias] = 'any';
        }
      }
    }

  },
  async mounted() {
    this.processFiltersParameters();
    await this.fetchCollectionMaterials(true);
  },
};
</script>

<style>
/* Custom styles for the component */
.card img {
  width: 100%;
  height: auto;
  /*  max-width: 400px;*/
  border-radius: 6px;
}

.vertical .card img {
  /*width: 50px;
  height: auto;*/
}

.other-articles .list-group-item {
  border: 0;
  padding: 8px;
  margin: 0 0 15px 0;

}

.without-thumbs.other-articles .list-group-item {
  background: #EFEFEF;
}

.other-articles .list-group-item.hl {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background: transparent;
  box-shadow: 0px 0px 6px 0px #e2e2e2;  
}

.other-articles .list-group-item.hl a{
/*  color: #FFF;*/
}

.other-articles .list-group-item.hl .text-primary{
  color:#FFF !important;
}

.without-thumbs.other-articles .list-group-item img{
  width: 35%;
}

.doc-icon {
  font-size: 30px;
  margin-right: 8px;
}

.summary .val{
  white-space: nowrap;
}

.tags .badge{
  cursor: pointer;
}

.summary-card {
  display: flex;
  justify-content: space-between;
  background-color: #f8f9fa;
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.badge.selected {
  background-color: #007bff !important;
  color: #fff;
}

.summary-card.tags {
  padding-bottom: 0; /* Уменьшаем отступ снизу для плашки с тегами */
}

.materials-list-scroll{
  height: 800px;
  overflow-y: auto;
}

</style>
