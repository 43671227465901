<template>
  <div class="article-dialogue">
    <div class="tags">
      <span v-for="tag in tags" :key="tag.id" class="tag" :style="{ backgroundColor: tag.color_hex }">{{ tag.tag }}</span>
    </div>
    <div v-for="(message, index) in dialogue" class="message" :key="index" :class="{'operator-message': message.user_type === 'operator', 'user-message': message.user_type === 'user'}">
      <div v-if="message.user_type === 'operator'" class="avatar operator-avatar"><img :src="operatorAvatarUrl"></div>
      <div v-else class="avatar user-avatar"><img :src="userAvatarUrl"></div>
      <div class="message-content">
        <span v-if="message.user_name" class="user-name">{{ message.user_name }}:</span>
        <div class="message-text">
          <template v-for="(part, partIndex) in splitMessageText(message.html_with_tags)" :key="partIndex">
            <span v-if="part.type === 'text' && !part.isTag">{{ part.text }}</span>
            <span v-else-if="part.type === 'tag'" class="message-tag" :style="{ backgroundColor: getTagColor(part.tagId) }" v-html="part.text"></span>
          </template>
        </div>
        <div class="message-tags">
          <span v-for="tagId in message.found_tags" :key="tagId" class="tag" :style="{ backgroundColor: getTagColor(tagId) }">{{ getTagName(tagId) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from '@/config.js';
import { mapGetters } from 'vuex';
import userAvatar from '@/assets/user.png';
import operatorAvatar from '@/assets/operator.png';

export default {
  props: {
    materialHash: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      collectionArticles: [],
      collection: {},
      collectionFilters: [],
      collectionSummaryReport: {},
      selectedCollectionFilter: {},
      dialogue: [],
      tags: [],
      userAvatarUrl: userAvatar,
      operatorAvatarUrl: operatorAvatar
    };
  },
  computed: {
    ...mapGetters('article', ['getArticleItem']),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
  },
  methods: {
    async fetchArticleDialogue() {
      try {
        const params = {
          action: 'get_article_dialogue',
          materialHash: this.materialHash
        }

        const response = await this.$axios.get(API_URL, {
          params: params,
        });

        this.dialogue = response.data.result.dialogue;
        this.tags = response.data.result.tags;

      } catch (error) {
        console.error('Error fetching article dialogue:', error);
      }
    },
    getTagColor(tagId) {
      const tag = this.tags.find(tag => tag.id === tagId);
      return tag ? tag.color_hex : '';
    },
    getTagName(tagId) {
      const tag = this.tags.find(tag => tag.id === tagId);
      return tag ? tag.tag : '';
    },
    splitMessageText(text) {
      const parts = [];
      const tagRegex = /<span data-tag-id="(\d+)">(.*?)<\/span>/g;
      let match;
      let currentIndex = 0;
      while ((match = tagRegex.exec(text)) !== null) {
        if (match.index > currentIndex) {
          parts.push({ type: 'text', text: text.substring(currentIndex, match.index), isTag: false });
        }
        parts.push({ type: 'tag', tagId: match[1], text: match[0], isTag: true });
        currentIndex = match.index + match[0].length;
      }
      if (currentIndex < text.length) {
        parts.push({ type: 'text', text: text.substring(currentIndex), isTag: false });
      }
      return parts;
    }

  },
  async mounted() {
    await this.fetchArticleDialogue();
  },
};
</script>

<style scoped>
.article-dialogue {
  display: flex;
  flex-direction: column;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tag {
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: white;
  font-weight: bold;
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message .tag{
  font-weight: normal;
  font-size: 0.9em;
}

.operator-message {
  background-color: #f0f0f0; /* Цвет фона для сообщений оператора */
  border-radius: 10px;
  padding: 10px;
}

.user-message {
  background-color: white; /* Цвет фона для сообщений пользователя */
  border-radius: 10px;
  border: 1px solid #EFEFEF;
  padding: 10px;
}

.avatar {
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar img{
  width: 85%;
  height: auto;
}

.operator-avatar {
  background-color: #c6d8ff; /* Цвет фона для аватара оператора */
}

.user-avatar {
  background-color: #f1f1f1; /* Цвет фона для аватара пользователя */
}

.user-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.message-text {
  margin-bottom: 5px;
}

.message-tag {
  padding: 2px 5px;
  border-radius: 3px;
  margin-right: 2px;
  color: #FFF;
}


</style>
