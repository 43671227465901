<template>
  <div class="">
    <h1 class="my-4" v-if="usersType == 'students'">Список студентов</h1>
    <h1 class="my-4" v-if="usersType == 'curators'">Список кураторов</h1>

    <div class="alert alert-info" v-if="usersType == 'curators'">
      Добавьте пользователя в группу курса «{{collectionItem.tg_group_caption}}»
    </div>
<!--     
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <input
          type="text"
          v-model="newUserUsername"
          placeholder="Введите username нового студента"
          class="form-control"
        />
        <button @click="addNewUser" class="btn btn-primary ms-2 ">Добавить&nbsp;студента</button>
      </div>
    </div>
 -->

    <table class="table table-striped">
      <thead>
        <tr>
          <th>Username</th>
          <th>Статус</th>
          <th>Дата</th>          
          <th>Действия</th>
        </tr>
      </thead>
      <tbody>

        <tr v-for="user in users" :key="user.id">
          <td>{{ user.username }}</td>
          <td>{{ user.status_caption }}</td>          
          <td>{{ user.status_date }}</td>
          <td>
            <button @click="handleAcceptUser(user)" v-if="user.status == 'applied'" class="btn btn-success btn-sm me-3">Принять</button>
            <button @click="confirmDelete(user)" class="btn btn-danger btn-sm">Удалить</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
  export default {
    props: {
      collectionId: {
        type: Number,
        required: true
      },
      usersType: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        newUserUsername: ""
      };
    },
    computed: {
      ...mapGetters("collection", ["getUsers", "getCollectionItem"]),  // Изменено согласно вашему Vuex модулю
      users() {
        return this.getUsers;  // Вы можете добавить фильтрацию по collectionId, если это необходимо
      },
      collectionItem: {
        get() {
          return this.getCollectionItem || {};
        },
        set(value) {
          this.updateCollectionItem(value);
        },
      },
    },
    methods: {
      ...mapActions("collection", ["fetchUsers", "addUser", "removeUser", "applyUser", "acceptUser"]), // Изменено согласно вашему Vuex модулю

      addNewUser() {
        if (this.newUserUsername) {
          this.addUser({ 
            collectionId: this.collectionId, 
            user: { username: this.newUserUsername}
          });
          this.newUserUsername = "";
        }
      },

      handleAcceptUser(user){
          this.acceptUser({ 
            collectionId: this.collectionId, 
            user: user
          });
      },

      confirmDelete(user) {
        console.log(user)
        if (confirm("Вы уверены, что хотите удалить этого студента?")) {
          this.removeUser( {collectionId: this.collectionId, user: user} );  // Изменено согласно вашему Vuex модулю
        }
      },


    },
    created() {
      //console.log(this.collectionItem);
      this.fetchUsers({collectionId: this.collectionId, usersType: this.usersType});
      this.$store.dispatch('collection/setActiveCollection', { collectionId: this.collectionId, 'active_tab': this.usersType });


      this.collectionTgGroupInterval = setInterval(() => {
        this.fetchUsers({collectionId: this.collectionId, usersType: this.usersType});
      }, 5000);

      
    },
    beforeUnmount() {
      console.log('cancel interval ', this.usersType);


      if (this.collectionTgGroupInterval) {
        clearInterval(this.collectionTgGroupInterval);
      }

      this.$store.commit('collection/setUsers', []);
    },    
  };
</script>
